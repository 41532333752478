html,
body {
  /* max-width: 100%; */
  /* overflow-x: hidden; */
}

.ql-editor {
  padding: 0px;
  padding-right: 10px;
  min-height: 500px;
  font-size: 21px;
  font-weight: 400;
  line-height: 1.58;
  letter-spacing: -0.004em;
  padding-left: 10px;
  padding-top: 16px;
}

.ql-editor:before {
  margin-top: 0px;
  padding-left: 0px;
}
.quill-blur {
  display: none;
}

.ql-toolbar {
  border: 0px !important;
  position: sticky;
  background-color: white;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 12px 0px;
  z-index: 10;
}

.ql-container {
  border: 0px !important;
}

@media screen and (min-width: 0) and (max-width: 991px) {
  .edit-focus .quill {
    position: absolute;
    top: 0;
    height: 226px;
    overflow: hidden;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
  }
  .edit-focus .quill-blur {
    display: block;
    position: absolute;
    top: 226px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
  }
  .edit-focus .ql-editor {
    height: 160px;
    border-bottom: 1px solid #ccc;
  }
  .edit-focus .navbar {
    display: none;
  }
  .edit-focus .main-panel {
    padding: 0 !important;
  }
  .edit-focus .main-panel .main-content {
    min-height: unset !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}
